import React, { useEffect, useState } from "react";
import { useAuth } from "@/context/authProvider.js";
import { useAccount, useBalance } from "wagmi";
import { formatUnits } from "viem";
import Form from "./components/Form";
import { User } from "@/types/user";
import LoadingSpinner from "../components/LoadingSpinner";

const Profile: React.FC = () => {
  const [PMBValue, setPMBValue] = useState<string>("-");
  const [PMEValue, setPMEValue] = useState<string>("-");
  const [PMGValue, setPMGValue] = useState<string>("-");

  const { user } = useAuth();
  const { address } = useAccount();

  const PMBResult = useBalance({
    address,
    token: process.env.REACT_APP_PMB_CONTRACT_ADDRESS as `0x${string}`,
  });
  const PMEResult = useBalance({
    address,
    token: process.env.REACT_APP_PME_CONTRACT_ADDRESS as `0x${string}`,
  });
  const PMGResult = useBalance({
    address,
    token: process.env.REACT_APP_PMG_CONTRACT_ADDRESS as `0x${string}`,
  });
  useEffect(() => {
    if (PMBResult.data) {
      const PMBVal = formatUnits(PMBResult.data.value, PMBResult.data.decimals);
      setPMBValue(PMBVal);
    }
  }, [PMBResult]);

  useEffect(() => {
    if (PMEResult.data) {
      const PMEVal = formatUnits(PMEResult.data.value, PMEResult.data.decimals);
      setPMEValue(PMEVal);
    }
  }, [PMEResult]);
  useEffect(() => {
    if (PMGResult.data) {
      const PMGVal = formatUnits(PMGResult.data.value, PMGResult.data.decimals);
      setPMGValue(PMGVal);
    }
  }, [PMGResult]);
  return (
    <>
      {user ? (
        <Form user={user as User} PMBValue={PMBValue} PMEValue={PMEValue} PMGValue={PMGValue} />
      ) : (
        <table className="w-100">
          <LoadingSpinner colSpan={5} />
        </table>
      )}
    </>
  );
};

export default Profile;
