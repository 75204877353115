import { useState } from "react";
import Modal from "./Modal";
import Card from "./Card";

const CardContainer = ({ nftData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [nftDetails, setNftDetails] = useState(null);

  const closeModalHandler = () => {
    setOpenModal(false);
  };
  const handleStacking = ({ token_id }) => {
    setNftDetails({ token_id });
    setOpenModal(true);
  };

  return (
    <div className="row g-3 g-xl-4 mb-5">
    

      {nftData.map(([id, token]) => (
        <div key={id} className="col-xl-5 col-xxl-4 col-lg-6 col-12">
          <Card
            tokenId={id}
            tile_count={token.tile_count}
            purchasepriceusd={token.purchasepriceusd}
            image={`data:image/png;base64,${token.picture}`}
            landtype={token.landtype}
            handleStacking={() => handleStacking({ token_id: id })}
            coordination={token.tile_data[0]}
            is_staked={token.is_staked}
          />
        </div>
      ))}
      {openModal && (
        <Modal closeModalHandler={closeModalHandler} nftDetails={nftDetails} />
      )}
    </div>
  );
};

export default CardContainer;
