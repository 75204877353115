import { useState } from "react";
import { useAccount } from "wagmi";
import EmptyPage from "../components/EmptyPage";
import CardContainer from "./components/CardContainer";
import { useNFTDataQuery } from "@/queries/nft";
import { toast } from "react-toastify";
import { api } from "@/utils/axiosInstance";
import { copyToClipboard } from "@/utils/copyToClipboard.js";

const MyNFTs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const { data: nftList, isLoading: nftLoading } = useNFTDataQuery(address);
  const nftData = nftList ? Object.entries(nftList.user_tokens) : [];

  const refreshPageHandler = () => {
    setIsLoading(true);
    api
      .post("/dashboard_refresh_nft/", {
        user_address: address,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.status === 202) {
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        if (error.status === 429 || error.status === 202) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className="container-xxl p-3 p-lg-5">
      {nftData.length > 0 && (
        <>
          {" "}
          <h3>How to Import an NFT into Your Wallet</h3>
          <div className="fs-5 mb-4">
            To manually import an NFT into your wallet, open your wallet and go
            to the "NFT" tab.
            <br />
            Click on "Import NFTs" or "Add Token." <br /> Enter the token
            address:
            <div
              className="input-group flex-nowrap my-2"
              style={{ maxWidth: "max-content" }}
            >
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="username"
                defaultValue={
                  process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS
                }
                readOnly
              />
              <span
                className="input-group-text"
                onClick={() =>
                  copyToClipboard(
                    process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS
                  )
                }
              >
                <i className="bi me-1 bi-copy"></i>
              </span>
            </div>
            in the "Address" field and the Token/Land ID of your NFT in the
            "Token ID" field. <br /> Confirm by clicking "Add" or "Import."
          </div>
        </>
      )}
      {!nftLoading && (
        <>
          <h5>Notice:</h5>
          <div className="d-flex justify-content-between flex-wrap gap-2 mb-4">
            <span className="fs-5 mb-2">
              If you're experiencing issues with your NFT, please press the
              'Reload NFTs' button once to refresh.
            </span>
            <button
              onClick={refreshPageHandler}
              className="btn btn-outline- btn-outline-warning d-flex me-auto"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Reload NFTs"
              )}
            </button>
          </div>
        </>
      )}
      {nftLoading ? (
        <div className="text-center">
          <span className="spinner-border spinner-border-md"></span>
        </div>
      ) : nftData.length > 0 ? (
        <CardContainer nftData={nftData} />
      ) : (
        <EmptyPage title="You have no NFT" />
      )}
    </div>
  );
};

export default MyNFTs;
