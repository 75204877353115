import Row from "./Row";
import LoadingSpinner from "../../components/LoadingSpinner";
import NoData from "../../components/NoData";

const Table = ({ list, isLoading }) => {
  return (
    <div className="table-responsive mb-5">
      <table className="table table-hover text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Tx Hash</th>
            <th scope="col">Date</th>
            <th scope="col">PME Amount</th>
            <th scope="col">PMG Amount</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingSpinner colSpan={5} />
          ) : list && list.length ? (
            list.map((log, index) => <Row key={log.stake_id} log={log} />)
          ) : (
            <NoData colSpan={5} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
