import React, { useState, FormEvent } from "react";
import { Card } from "@/components/Common/Card";
import { maticTokenAddress } from "@/utils/contractConstants";
import { useBalance, useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { FormProps } from "../types";

const Form: React.FC<FormProps> = ({
  isLoading,
  transactionReceipt,
  checkIsUserDuplicated,
  handleChange,
  formData,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { email, username, referral_id } = formData;
  const { address } = useAccount();
  const maticBalance = useBalance({ address, token: maticTokenAddress });
  const queryClient = useQueryClient();

  const validate = (): { [key: string]: string } => {
    const errors: { [key: string]: string } = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!username) {
      errors.username = "Username is required";
    } else if (username.length < 3) {
      errors.username = "Username must be at least 3 characters";
    }

    if (!termsAccepted) {
      errors.terms = "You must accept the terms and conditions";
    }

    return errors;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries({ queryKey: maticBalance.queryKey });
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (Number(maticBalance?.data?.formatted) < 0.1) {
        toast.error(
          "Your Matic balance is not sufficient to complete the signup.",
          { toastId: "maticBalanceError" }
        );
      } else {
        checkIsUserDuplicated({ username, email, referral_id });
      }
    }
  };

  return (
    <div className="login">
      <div className="login-content">
        {transactionReceipt.isFetching ? (
          <Card className="p-4 transaction-info">
            <p>
              Your transaction is being processed. Please wait for confirmation.
              You will be notified once the transaction is successful.
            </p>
          </Card>
        ) : (
          <form onSubmit={handleSubmit}>
            <h1 className="text-center">Sign Up</h1>
            <div className="mb-3">
              <label className="form-label">
                Email Address <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control form-control-lg bg-white bg-opacity-5 ${
                  errors.email ? "is-invalid" : ""
                }`}
                name="email"
                value={email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                Username <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control form-control-lg bg-white bg-opacity-5 ${
                  errors.username ? "is-invalid" : ""
                }`}
                name="username"
                value={username}
                onChange={handleChange}
              />
              {errors.username && (
                <div className="text-danger">{errors.username}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Referral Code</label>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                placeholder="0x"
                name="referral_id"
                value={referral_id}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="terms"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              <label className="form-check-label" htmlFor="terms">
                I accept the{" "}
                <a
                  href="/terms-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>
              </label>
              {errors.terms && (
                <div className="text-danger">{errors.terms}</div>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-4"
              disabled={isLoading}
            >
              {isLoading ? <div className="spinner-border"></div> : "Sign Up"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Form;
