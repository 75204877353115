import { useState, useEffect } from "react";
import Table from "./components/Table";
import { api } from "@/utils/axiosInstance.js";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";

const ClaimedHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [claimedList, setClaimedlist] = useState(null);
  const { address } = useAccount();
  const { pathname } = useLocation();
  const getClaimedHistory = async () => {
    setIsLoading(true);
    api
      .post("/dashboard_claimed_history/", { user_address: address })
      .then((response) => {
        setIsLoading(false);
        setClaimedlist(response.data.claimed_logs);
      });
  };
  useEffect(() => {
    getClaimedHistory();
  }, [pathname]);
  return <Table isLoading={isLoading} list={claimedList} />;
};

export default ClaimedHistory;
