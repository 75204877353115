import { formatDate } from "@/utils/formatDate";
const Row = ({ log }) => {
  return (
    <tr>
      <td className="p-md-2 w-85px align-middle">{log.stake_id}</td>
      <td className="p-md-2 align-middle">
        <a
          href={`https://polygonscan.com/tx/${log.tx_hash}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white"
        >
          {log.tx_hash}{" "}
        </a>
      </td>
      <td className="p-md-2 align-middle">{formatDate(log.date)}</td>
      <td className="p-md-2 align-middle">{log.pme_amount}</td>
      <td className="p-md-2 align-middle">{log.pmg_amount}</td>
    </tr>
  );
};

export default Row;
