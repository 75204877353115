export const termsAndConditions = [
  {
    title: "1. Acceptance of Terms",
    items: [
      "By accessing or using the PCO Meta Earth website and services, you agree to be bound by these Terms of Service. If you do not agree to these Terms, you should discontinue using our Services immediately."
    ]
  },
  {
    title: "2. Eligibility and Account Security",
    items: [
      "Eligibility: You must be at least 18 years old to use our Services.",
      "Account Registration: You agree to provide accurate, complete information during registration and maintain the confidentiality of your account credentials. You are solely responsible for any activity under your account."
    ]
  },
  {
    title: "3. Private Key and Asset Security",
    items: [
      "PCO Meta Earth does not store or manage private keys. You are responsible for securing your private keys and wallet credentials. If lost, we cannot assist in recovery of your assets.",
      "You acknowledge that loss of private keys may result in the permanent loss of your digital assets. PCO Meta Earth shall not be liable for any such losses."
    ]
  },
  {
    title: "4. Prohibited Activities",
    items: [
      "You agree not to engage in any unlawful activities on the platform, including fraud, harassment, or distributing malicious software."
    ]
  },
  {
    title: "5. Ownership and Intellectual Property",
    items: [
      "All content on the platform, including text, graphics, logos, and software, is the property of PCO Meta Earth and is protected by intellectual property laws. You may not reproduce, distribute, or use any content without express permission."
    ]
  },
  {
    title: "6. User Content",
    items: [
      "You retain ownership of any content you submit. By submitting content, you grant PCO Meta Earth a non-exclusive, royalty-free license to use, display, and distribute your content in connection with our Services."
    ]
  },
  {
    title: "7. Limitation of Liability",
    items: [
      "PCO Meta Earth is not liable for any indirect, incidental, or consequential damages resulting from your use of the platform.",
      "Our liability is limited to the amount you paid for our Services, if any."
    ]
  },
  {
    title: "8. Termination of Services",
    items: [
      "We reserve the right to suspend or terminate your access to our Services without prior notice for any activity that violates these Terms or harms other users or the platform."
    ]
  },
  {
    title: "9. Risk Disclaimer and Blockchain Technology",
    items: [
      "Using blockchain technology carries inherent risks, including market fluctuations and technical vulnerabilities. You acknowledge that transactions on the blockchain are irreversible, and PCO Meta Earth is not responsible for any loss or damage related to such transactions."
    ]
  },
  {
    title: "10. Governing Law and Dispute Resolution",
    items: [
      "These Terms are governed by and interpreted in accordance with the principles of international business law. Any disputes arising from these Terms or the use of our Services will be resolved through binding arbitration in a neutral jurisdiction, mutually agreed upon by both parties."
    ]
  },
  {
    title: "11. Modifications to Terms",
    items: [
      "We reserve the right to update these Terms at any time. Notice of significant changes will be posted on the website. Continued use of the Services constitutes acceptance of the revised Terms."
    ]
  },
  {
    title: "12. Contact Us",
    items: [
      "For any inquiries, contact us at <a href='mailto:Info@pco.land'>Info@pco.land</a>."
    ]
  }
];


