import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from "./components/about";
import aboutData from "./data/aboutData";
import RoadMap from "./components/roadmap";
import roadMapData from "./data/roadMapData";
import Work from "./components/work";
import workData from "./data/workData";
import Blog from "./components/blog";
import blogData from "./data/blogData";
import FAQ from "./components/faq";
import faqData from "./data/faqData";
import HeroVideo from "./components/heroVideo";
import News from "./components/news";
import newsData from "./data/newsData";
import Exchanges from "./components/exhcanges";
import exchangesData from './data/exchangeData'
const Home = () => {
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="home-1 ">
      <div className=" hero-animate">
        <HeroVideo />
        <About data={aboutData} />
      </div>
      <RoadMap data={roadMapData} />
      <Work data={workData} />
      <Blog data={blogData} />
      <FAQ data={faqData} />
      <News data={newsData} />
      <Exchanges data={exchangesData} />
    </div>
  );
};

export default Home;
