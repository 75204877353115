import React from "react";
import { termsAndConditions } from "@/assets/fake-data/data-terms";
const TermsAndConditions = () => {
  return (
    <div className="post-details privacy">
      <section className="tf-section detail">
        <div className="container">
          <div className="row">
            <article
              className="article"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <div className="post p-b23">
                <h4 className="title">Terms and Conditions</h4>
                <span>Effective Date: 24 SEP 2024</span>
              </div>
              <div className="post-details">
                {termsAndConditions.map((term) => (
                  <div key={term.title}>
                    <h5>{term.title}</h5>
                    <ul className="list">
                      {term.items.map((item, index) => (
                        <li key={index}>
                          <div className="ms-2 ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="me-1"
                            >
                              <path
                                d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                                fill="white"
                              />
                            </svg>

                            <span
                              dangerouslySetInnerHTML={{ __html: item }}
                            ></span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
